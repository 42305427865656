import Vue from 'vue'
import App from './App.vue'
import VueTypedJs  from 'vue-typed-js'
import axios from "axios";
import router from './router'
// import Vconsole from 'vconsole'
// new Vconsole()
Vue.config.productionTip = false
Vue.use(VueTypedJs)
Vue.prototype.$http = axios

Vue.directive('title', {//单个修改标题
  inserted: function (el) {
    document.title = el.dataset.title
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
