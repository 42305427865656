<template>
<div> </div>
</template>

<script>
import {store} from '@/store/store'
import qs from 'qs';
export default {
  name: "index.vue",
  data(){
    return {
      id: 1,
      title: '',
      sn: '',
      resData: {}
    }
  },
  mounted() {
    let urls = window.location.href.split("/")
    var numReg = /^[0-9]*$/
    var numRe = new RegExp(numReg)
    var ids = urls[urls.length-1].replace(/\s*/g,'')
    if (ids.length === 0) {
      this.id = 1
    }else if (!numRe.test(ids)){
      this.sn = this.$route.query.sn
    }else {
      this.id = urls[urls.length-1]
    }
    this.getData()
  },
  methods: {
    async getData() {
      let res

      if (this.sn){
        let parms = {sn:this.sn}
        res = await this.$http({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(parms),
          url: '/api/blessing/info'
        })
      }else {
        res = await this.$http(
            '/api/blessing/' + this.id
        )
      }

      res = res.data
      if (res.code !== '00000') return

      this.requestData = res.data
      store.addTextBlessingData(res.data)
      if (this.sn){
        this.$router.push({
          path: `/bls?sn=`+this.sn,
        })
      }else {
        this.$router.push({
          path: `/bls?id=`+this.id,
        })
      }

    }
  }
}
</script>

<style scoped>

</style>