export function LineIs(flag, ctxIs, chIs, cwIs) {
  this.flag = flag
  this.ctx_is = ctxIs
  this.chIs = chIs
  this.cwIs = cwIs

  this.a = {}
  this.b = {}

  if (flag === 'v') {
    this.a.x = randomIntFromInterval(0, this.chIs)
    this.a.y = 0

    this.b.x = randomIntFromInterval(0, this.chIs)
    this.b.y = this.chIs
  } else if (flag === 'h') {
    this.a.x = 0
    this.a.y = randomIntFromInterval(0, cwIs)

    this.b.x = cwIs
    this.b.y = randomIntFromInterval(0, cwIs)
  }

  this.va = randomIntFromInterval(25, 100) / 100
  this.vb = randomIntFromInterval(25, 100) / 100

  this.draw = function() {
    this.ctx_is.strokeStyle = '#D8BFD8' // 线条的颜色
    this.ctx_is.beginPath()
    this.ctx_is.moveTo(this.a.x, this.a.y)
    this.ctx_is.lineTo(this.b.x, this.b.y)
    this.ctx_is.stroke()
  }

  this.update = function() {
    if (this.flag === 'v') {
      this.a.x += this.va
      this.b.x += this.vb
    } else if (flag === 'h') {
      this.a.y += this.va
      this.b.y += this.vb
    }

    this.edges()
  }

  this.edges = function() {
    if (this.flag === 'v') {
      if (this.a.x < 0 || this.a.x > this.cw_is) {
        this.va *= -1
      }
      if (this.b.x < 0 || this.b.x > this.cw_is) {
        this.vb *= -1
      }
    } else if (flag === 'h') {
      if (this.a.y < 0 || this.a.y > this.chIs) {
        this.va *= -1
      }
      if (this.b.y < 0 || this.b.y > this.chIs) {
        this.vb *= -1
      }
    }
  }
}

function randomIntFromInterval(mn, mx) {
  return ~~(Math.random() * (mx - mn + 1) + mn)
}
