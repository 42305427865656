<template>

  <div class="page" :style="{width:screenWidth + 'px', backgroundImage: 'url('+bg_src+')'}" v-title :data-title="title">
    <div class="mask"></div>
    <canvas id="canvasIs"></canvas>
    <div class="content">
      <div class="img-wrapper" v-show="!isShowEnvolop">
        <img
            class="congratulation"
            :src="top_bg"
        />
      </div>

      <div class="text" v-if="!isShowEnvolop">
        <vue-typed-js
            :strings="[letterText]"
            :cursorChar="cursorChar"
            contentType="html"
            :typeSpeed="80"
        >
          <div><span class="typing"></span></div>
        </vue-typed-js>
      </div>
    </div>
    <transition name="fade">
      <div class="envolop" v-show="isShowEnvolop">
        <transition name="scale">
          <img
              @click="clickEnvolop"
              class="img-envolop"
              src="@/assets/open_letter.jpg"
          />
        </transition>
      </div>
    </transition>

    <div class="div_btn more-btn" v-if="more_link !== ''">
      <a id="moreLink" :href="more_link">
        <span>更多</span>
      </a>
    </div>

    <div class="div_btn" id="div_music" @click="music_switch">
      <!-- 音乐按钮部分 -->
      <img
          ref="img_music"
          id="img_music"
          src="@/assets/music.png"
          :class="!is_play_music ? 'cancel-animation' : ''"
      />
      <audio id="audio_music" autoplay="autoplay" loop="loop" preload="auto" :src="music_src">
        <source type="audio/mpeg"/>
        <!-- 您的浏览器不支持播放音乐 -->
      </audio>
    </div>
  </div>
</template>

<script>
import {LineIs} from '@/utils/line'
import {store} from '@/store/store'
import wx from 'weixin-js-sdk'
// import qs from "qs";
// import qs from 'qs';

export default {
  name: 'Home',
  data() {
    return {
      requestId: null,
      id: 1,
      sn: '',
      password: '',
      screenWidth: 0,
      linesRy: [],
      isShowEnvolop: true,
      cursorChar: '💕',
      startCursor: 'cursor_heart',
      requestData: store.state.textBlessingData,
      bg_src: '',
      top_bg: '',
      more_link: '',
      music_src: '',
      title: '',
      audio_music: null,
      is_play_music: false,
      letterText:
          '凡是过往皆是序章，所有将来皆为可盼。2021再见，2022你好！这一年，失去、获得、释怀、成长、完结、下一年，新生、期待、希望、向前、遇见'
    }
  },
  created() {
    // this.setCursorChar()
  },
  mounted() {
    if (this.isMobile()) {
      this.screenWidth = document.body.clientWidth
    } else {
      this.screenWidth = 500
    }
    // let urls = window.location.href.split("/")
    // var numReg = /^[0-9]*$/
    // var numRe = new RegExp(numReg)
    // var ids = urls[urls.length - 1].replace(/\s*/g, '')
    // if (!numRe.test(ids) || ids.length === 0) {
    //   this.id = 1
    // } else {
    //   this.id = urls[urls.length - 1]
    // }
    this.id = this.$route.query.id
    this.sn = this.$route.query.sn
    this.password = this.$route.query.password
    this.getData()
    document.title = this.title

    this.initCanvas()

    setTimeout(() => {
      this.Init_is()

      addEventListener('resize', this.Init_is, false)
    }, 15)
    // 如果支持 popstate 一般移动端都支持了
    if (window.history && window.history.pushState && this.isWxMiniapp()) {
      // 往历史记录里面添加一条新的当前页面的url

      console.log("微信环境")
      history.pushState(null, null, document.URL);
      // 给 popstate 绑定一个方法 监听页面刷新
      window.addEventListener('popstate', this.backChange, false);//false阻止默认事件
    } else {
      console.log("不是微信环境")
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.backChange, false);
  },
  methods: {
    backChange() {
      console.log("微信返回")
      wx.miniProgram.navigateBack({delta: 1});
    },
    isMobile() {
      let sUserAgent = navigator.userAgent.toLowerCase()
      return /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent);
      // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      // console.log('flag-->'+flag)
      // return flag;
    },
    isWxMiniapp() {
      // var ua = navigator.userAgent.toLowerCase();
      // console.log(ua)
      // const isMiniProgram = /miniProgram/i.test(navigator.userAgent.toLowerCase())
      // console.log("是否小程序"+isMiniProgram)
      // if(ua.match(/MicroMessenger/i)==="micromessenger") {
      //   //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
      //   console.log("在微信")
      //   wx.miniProgram.getEnv((res)=>{
      //     return !!res.miniprogram;
      //   })
      // }else{
      //   return false
      // }
      return /miniProgram/i.test(navigator.userAgent.toLowerCase())
    },
    // 切换音乐
    music_switch() {
      if (this.audio_music.paused) {
        this.audio_music.play()
        this.is_play_music = true
      } else {
        this.audio_music.pause()
        this.is_play_music = false
      }
    },
    // 请求数据
    async getData() {
      // let res = await this.$http(
      //     '/api/blessing/info?id=' + this.id
      // )
      // res = res.data
      // if (res.code !== '00000') return
      //
      // this.requestData = res.data

      // console.log('res', res)
      // console.log(this.requestData.title === undefined)
      if (this.requestData.title === undefined) {
        if (this.sn) {
          this.$router.push({
            path: `/?sn=` + this.sn,
          })
        } else {
          this.$router.push({
            path: `/` + this.id,
          })
        }

        return

      }
      console.log(this.requestData)
      if (this.requestData.content) {
        this.letterText = this.requestData.content
      }
      this.bg_src = this.requestData.backgroundImg
      this.top_bg = this.requestData.topImg
      this.more_link = this.requestData.moreLink ? this.requestData.moreLink : ''
      this.title = this.requestData.title ? this.requestData.title : ''
      this.cursorChar = this.requestData.printIcon ? this.requestData.printIcon : '💕'

      if (this.requestData.music) {
        this.music_src = this.requestData.music
        this.audio_music = document.getElementById('audio_music')
        this.is_play_music = !this.audio_music.paused
        // setTimeout(() => {
        //   document.addEventListener("WeixinJSBridgeReady", function () {
        //     this.audio_music.play();
        //   }, false);
        //   // this.audio_music.pause()
        //   // this.audio_music.play()
        //   if (this.audio_music.paused){
        //     this.audio_music.play()
        //   }
        //   this.is_play_music = !this.audio_music.paused
        //
        //   console.log(this.audio_music.paused)
        //   // this.is_play_music = !this.audio_music.paused
        //   // this.is_play_music = false
        //
        // }, 500)
      }
    },
    // 设置文本末尾图标
    setCursorChar() {
      var strCursorChar = '❤'

      if (this.startCursor) {
        switch (this.startCursor) {
          case 'cursor_heart':
            strCursorChar = '❤'
            break
          case 'cursor_sub':
            strCursorChar = '_'
            break
          case 'cursor_music':
            strCursorChar = '♫'
            break
          case 'cursor_star':
            strCursorChar = '★'
            break
          case 'cursor_sun':
            strCursorChar = '☀'
            break
          default:
            strCursorChar = '|'
        }
      }

      this.cursorChar = strCursorChar
    },
    // 点击信封
    clickEnvolop() {
      this.isShowEnvolop = false
      console.log(this.audio_music.paused)
      if (this.audio_music.paused) {
        this.music_switch()
      }
    },
    // 初始化画布
    initCanvas() {
      this.canvasIs = document.getElementById('canvasIs')
      this.ctx_is = this.canvasIs.getContext('2d')

      console.log(this.canvasIs.width)
      console.log(window.innerWidth)
      // 设置画布的大小
      // this.cw_is = this.canvasIs.width = window.innerWidth
      this.cw_is = this.canvasIs.width = this.screenWidth
      console.log(this.cw_is)
      this.cx = this.cw_is / 2
      this.chIs = this.canvasIs.height = window.innerHeight
      console.log(this.chIs)
      this.cy = this.chIs / 2

      this.ctx_is.fillStyle = '#483D8B'
      this.linesNum = 25

      for (let i = 0; i < this.linesNum; i++) {
        var flag = i % 2 === 0 ? 'h' : 'v'
        var l = new LineIs(flag, this.ctx_is, this.chIs, this.cw_is)
        this.linesRy.push(l)
      }

      // console.log('this.linesRy', this.linesRy)
    },
    // 画线
    Draw_is() {
      this.requestId = window.requestAnimationFrame(this.Draw_is)
      this.ctx_is.clearRect(0, 0, this.cw_is, this.chIs)

      for (var i = 0; i < this.linesRy.length; i++) {
        const l = this.linesRy[i]
        l.draw()
        l.update()
      }

      for (let i = 0; i < this.linesRy.length; i++) {
        const l = this.linesRy[i]
        for (var j = i + 1; j < this.linesRy.length; j++) {
          var l1 = this.linesRy[j]
          this.Intersect2lines(l, l1)
        }
      }
    },

    // 启动
    Init_is() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId)
        this.requestId = null
      }

      this.Draw_is()
    },

    // 相交线
    Intersect2lines(l1, l2) {
      var p1 = l1.a
      var p2 = l1.b
      var p3 = l2.a
      var p4 = l2.b

      var denominator =
          (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y)

      var ua =
          ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) /
          denominator
      var ub =
          ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) /
          denominator

      var x = p1.x + ua * (p2.x - p1.x)
      var y = p1.y + ua * (p2.y - p1.y)

      if (ua > 0 && ub > 0) {
        this.markPoint({x: x, y: y})
      }
    },

    // 生成相交点
    markPoint(p) {
      this.ctx_is.beginPath()
      this.ctx_is.fillStyle = '#DDA0DD' // 相交点的颜色
      this.ctx_is.arc(p.x, p.y, 2, 0, 2 * Math.PI)
      this.ctx_is.fill()
    }
  }
}
</script>

<style scoped>
.page {
  /*width: 100%;*/
  /*height: 100%;*/
  /*width: 500px;*/
  height: 100vh;
  /*background-image: url('https://txmov2.a.yximgs.com/ufile/atlas/NTI1NTcwMDgwOTU2ODQwNDE2Ml8xNjEyMTc3MDExNTgy_3.jpg');*/
  background-size: cover;
  position: relative;
  overflow: hidden;
  align-self: center;
  margin: 0 auto;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;
  opacity: 0.85;
}

#canvasIs {
  position: absolute;
  z-index: 10;
  margin: 0px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 15;

  padding-top: 60px;
}

.content {
  width: 80%;
  margin: 0 auto;
}

.content .img-wrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;

  padding: 6px;
}

.content .img-wrapper .congratulation {
  width: 100%;
  display: block;
}

.content .text {
  width: 100%;
  text-align: justify;
  padding: 10px 6px;
  margin: 0 -6px;
  font-size: 14px;
  line-height: 1.6;
}

.envolop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 20;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40%;
}

.img-envolop {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.26);
  animation: breath 2s linear infinite;
}

.more-btn {
  position: fixed;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: 2px solid white;
  border-radius: 50%;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  z-index: 999;
  top: 30%;
  background-color: rgba(0, 0, 0, 0.3);
  right: 10px;
}

.more-btn a {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.div_btn#div_music {
  position: fixed;
  cursor: pointer;
  width: 42px;
  height: 42px;
  border: 2px solid white;
  border-radius: 50%;
  font-size: 1em;
  line-height: 40px;
  text-align: center;
  z-index: 999;
  right: 10px;
  border: none;
  top: 20px;
}

#img_music {
  width: 30px;
  height: 30px;
  animation: music_play_rotate 1s linear infinite;
}

#img_music.cancel-animation {
  animation: '';
}

@keyframes breath {
  0% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.4);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.2);
  }
}

@-webkit-keyframes music_play_rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
